import Homepage from "./Views/Homepage/Homepage";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { Routes, Route, Navigate } from "react-router-dom";
import ErrorPage from "./Views/ErrorPage/ErrorPage";
import ThankYou from "./Views/ThankYou/ThankYou";
import Banner from "./Components/Banner/Banner";
import { useEffect } from "react";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-235429748-1');

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/confirmEmail/:id" element={<ThankYou />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

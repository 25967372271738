import React, { useEffect, useState } from "react";
import { ReactComponent as MotoGPLogo } from "../../Assets/images/motogp-logo.svg";
import Bike from "../../Assets/images/polygonal-bike.png";
import axios from "axios";
import cogoToast from "cogo-toast";

export default function ThankYou() {
  const url = window.location.href;
  const param = url.split("/")[4].toString();
  useEffect(async () => {
    try {
      let response = await axios.get(
        "https://raffle.cryptodata.com/motogp/validateEmail/" + param
      );

      if (response.status === 200) {
        cogoToast.success("Your email has been successfully confirmed");
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        switch (err.response.status) {
          default:
            cogoToast.error("Oops.. There was an error!");
            return;
        }
      }
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-centern align-content-center flex-column mt-5 pt-5">
        <div className="m-auto d-flex flex-column py-5">
          {" "}
          <div className="d-flex">
            <img src={Bike} alt="" className="m-auto img-fluid p-3" />
          </div>
          <MotoGPLogo className=" mt-4 mx-auto img-fluid p-3" />
          <div className="font-size-64 text-center moto-gp-display text-center px-3">
            Thank you for confirming your email!
          </div>
          <div className="font-size-32 text-center moto-gp-regular text-center px-2">
            It's official! You now have a confirmed chance to win a Ducati bike
            or one of our other special prizes. Looking forward to seeing you at
            the race!{" "}
          </div>
        </div>
      </div>
    </>
  );
}

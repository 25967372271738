import React from 'react';
import { Col, Row } from 'react-bootstrap';

import MinteRImg from '../../../Assets/images/background.png';
import DashImg from '../FAQ/Assets/dash.png';
import AccessTicket from '../Prizes/Assets/ga-ticket.png';
import HidrImg from '../Prizes/Assets/hidr.png';
import ImpulseImg from '../Prizes/Assets/impulse.png';
import VipTicket from '../Prizes/Assets/vip-ticket.png';
import BasmImg from '../Prizes/Assets/basm-prop.png'

import './Winners.scss';

const Winners = () => {
    return (
        <section id="winners-moto-gp" className="mt-5">
            <div className="main-container d-flex flex-align-center justify-content-center flex-column">
                <div className="title mb-4">
                    <h2 className="font-size-120 moto-gp-display text-center text-lg-start">
                        Winners
                    </h2>
                </div>

                <img
                    src={DashImg}
                    alt="dash"
                    className="img-fluid dashes-img mb-5 mx-auto mx-lg-0"
                />

                <Row xs={1} sm={1} md={2} lg={2} xl={2} className="gy-4">

                    <Col>
                        <div className="winner-wrapper d-flex align-items-start justify-content-start mb-3">

                            <img src={AccessTicket} alt="access ticket" className='card-ticket me-3' />

                            <div className='text-content-container'>
                                <div className="font-size-28 moto-gp-display mb-3">Access Ticket</div>
                                <div className="font-size-20 moto-gp-regular line-height-11 mb-2">Check your email to see if you are one of the lucky winners.</div>
                                <div className="font-size-16 moto-gp-regular line-height-11"><span className="link-color">*</span>Winners have 24 hours to confirm their prize, otherwise another winner will be selected.</div>
                            </div>

                        </div>
                    </Col>

                    <Col>
                        <div className="winner-wrapper d-flex align-items-start justify-content-start">

                            <img src={VipTicket} alt="access ticket" className='card-ticket me-3' />

                            <div className='text-content-container'>
                                <div className="font-size-28 moto-gp-display mb-3">Vip Ticket</div>
                                <div className="font-size-20 moto-gp-regular line-height-11 mb-2">Check your email to see if you are the lucky winner.</div>
                                <div className="font-size-16 moto-gp-regular line-height-11"><span className="link-color">*</span>The winner has 24 hours to confirm their prize, otherwise another winner will be selected.</div>
                            </div>

                        </div>
                    </Col>

                    <Col>
                        <div className="mt-3 mt-md-0 winner-wrapper d-flex align-items-start justify-content-start">

                            <img src={ImpulseImg} alt="access ticket" className='impulse-ticket me-3' />

                            <div className='text-content-container'>
                                <div className="font-size-28 moto-gp-display mb-3">IMPulse</div>
                                <div className="font-size-20 moto-gp-regular line-height-11 mb-2">Check your email to see if you are the lucky winner.</div>
                                <div className="font-size-16 moto-gp-regular line-height-11"><span className="link-color">*</span>The winner has 24 hours to confirm their prize, otherwise another winner will be selected.</div>
                            </div>

                        </div>
                    </Col>


                    <Col>
                        <div className="mt-3 mt-md-0 winner-wrapper d-flex align-items-start justify-content-start">

                            <img src={HidrImg} alt="access ticket" className='impulse-ticket me-3' />

                            <div className='text-content-container'>
                                <div className="font-size-28 moto-gp-display mb-3">HidR</div>
                                <div className="font-size-20 moto-gp-regular line-height-11 mb-2">Check your email to see if you are the lucky winner.</div>
                                <div className="font-size-16 moto-gp-regular line-height-11"><span className="link-color">*</span>The winner has 24 hours to confirm their prize, otherwise another winner will be selected.</div>
                            </div>

                        </div>
                    </Col>
                    <Col>
                        <div className="mt-4 mt-md-0 winner-wrapper d-flex align-items-start justify-content-start">

                            <img src={MinteRImg} alt="access ticket" className='impulse-ticket me-3 my-auto' />

                            <div className='text-content-container'>
                                <div className="font-size-28 moto-gp-display mb-3">MinteR</div>
                                <div className="font-size-20 moto-gp-regular line-height-11 mb-2">Check your email to see if you are the lucky winner.</div>
                                <div className="font-size-16 moto-gp-regular line-height-11"><span className="link-color">*</span>The winner has 24 hours to confirm their prize, otherwise another winner will be selected.</div>
                            </div>

                        </div>
                    </Col>
                    <Col>
                        <div className="mt-4 mt-md-0 winner-wrapper d-flex align-items-start justify-content-start">

                            <img src={BasmImg} alt="access ticket" className='impulse-ticket me-3 my-auto' />

                            <div className='text-content-container'>
                                <div className="font-size-28 moto-gp-display mb-3">BASM</div>
                                <div className="font-size-20 moto-gp-regular line-height-11 mb-2">Check your email to see if you are the lucky winner.</div>
                                <div className="font-size-16 moto-gp-regular line-height-11"><span className="link-color">*</span>The winner has 24 hours to confirm their prize, otherwise another winner will be selected.</div>
                            </div>

                        </div>
                    </Col>

                </Row>

            </div>
        </section>
    )
}

export default Winners
import React from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { Link as ScrollLink } from "react-scroll";

import "./Banner.scss";

const Banner = () => {
  return (
    <div className="banner-section">
      <div className="banner-li-container d-flex align-items-center justify-content-between container-navbar px-3">
        <h4 className="font-size-24 moto-gp-display text-white mb-0">
          MotoGP™ Raffle
        </h4>

        <ul className="d-flex flex-row align-items-center justify-content-between mb-0 ul-container">
          <ScrollLink to="hero-anchor-id" offset={50} spy={true}>
            <li className="font-size-18 moto-gp-regular li-color">Overview</li>
          </ScrollLink>

          <ScrollLink to="ticket-scroll-anchor" offset={-90} spy={true}>
            <li className="font-size-18 moto-gp-regular li-color">About</li>
          </ScrollLink>

          <ScrollLink to="prizes-section" offset={-90} spy={true}>
            <li className="font-size-18 moto-gp-regular li-color">Prizes</li>
          </ScrollLink>

          <ScrollLink to="faq-moto-gp" offset={-90} spy={true}>
            <li className="font-size-18 moto-gp-regular li-color">FAQ</li>
          </ScrollLink>

          {/* <ScrollLink to="register-form-id" offset={-90} spy={true}>
            <li className="register-btn position-relative">
              <span className="register-btn-txt white-color moto-gp-regular font-size-18">
                Register
              </span>
              <span className="register-btn-shape"></span>
            </li>
          </ScrollLink> */}
        </ul>

        {/* MOBILE BANNER */}

        <div className="mobile-banner">
          <DropdownButton title="">
            <Dropdown.Item href="#/action-1">
              <ScrollLink to="hero-anchor-id" offset={50} spy={true}>
                <li className="font-size-18 moto-gp-regular li-color">
                  Overview
                </li>
              </ScrollLink>
            </Dropdown.Item>

            <Dropdown.Item href="#/action-2">
              <ScrollLink to="ticket-scroll-anchor" offset={-90} spy={true}>
                <li className="font-size-18 moto-gp-regular li-color">About</li>
              </ScrollLink>
            </Dropdown.Item>

            <Dropdown.Item href="#/action-3">
              <ScrollLink to="prizes-section" offset={-90} spy={true}>
                <li className="font-size-18 moto-gp-regular li-color">
                  Prizes
                </li>
              </ScrollLink>
            </Dropdown.Item>

            <Dropdown.Item href="#/action-4">
              <ScrollLink to="faq-moto-gp" offset={-90} spy={true}>
                <li className="font-size-18 moto-gp-regular li-color">FAQ</li>
              </ScrollLink>
            </Dropdown.Item>

            {/* <Dropdown.Item href="#/action-5">
              <ScrollLink to="register-form-id" offset={-90} spy={true}>
                <li className="register-btn position-relative">
                  <span className="register-btn-txt white-color moto-gp-regular font-size-18">
                    Register
                  </span>
                  <span className="register-btn-shape"></span>
                </li>
              </ScrollLink>
            </Dropdown.Item> */}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default Banner;

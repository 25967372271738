import React from "react";

import Race from "./Assets/moto-gp-ticket.png";
import Flags from "./Assets/moto-gp-flags.png";

import "./Ticket.scss";

const Ticket = () => {
  return (
    <section className="ticket-section mt-5" id="ticket-scroll-anchor">
      <div className="main-container d-flex align-items-center justify-content-center">
        <div className="ticket-container d-flex flex-row align-items-center justify-content-center">
          <img src={Race} alt="moto-flag" className="img-fluid me-5 moto-img" />

          <h4 className="font-size-64 moto-gp-display ticket-txt white-color line-height-1 mb-0 pe-3">
            Receive a 200$ discount <br></br> on your order with your MotoGP™
            ticket.
          </h4>

          <div className="flags-container d-flex flex-column align-items-center justify-content-center ms-5">
            <img
              src={Flags}
              alt="moto-flags"
              className="img-fluid moto-flags-img"
            />
            <h4 className="font-size-64 mobile-ticket-txt moto-gp-display white-color line-height-1 mb-0 text-center mb-2">
              Receive a 200$ discount on your <br></br>order with your MotoGP™
              ticket.
            </h4>
            <button
              className="shop-btn position-relative"
              onClick={() =>
                window.open("https://cryptodata.com/shop", "_blank")
              }
            >
              <span className="shop-btn-txt white-color moto-gp-regular font-size-20 text-nowrap">
                Shop now
              </span>
              <span className="shop-btn-shape"></span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ticket;

import React, { useState } from "react";
// style
import "./Footer.scss";
// components
import { Row, Col, Accordion, InputGroup, Form, Card } from "react-bootstrap";
import { ReactComponent as Logo } from "./Assets/logo-crypto.svg";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
// react router
import CryptoDataLogo from "./Assets/logo.png";
import { ReactComponent as FaceBookLogo } from "../../Assets/Icons/facebook.svg";
import { ReactComponent as Instagram } from "../../Assets/Icons/instagram.svg";
import { ReactComponent as TwitterLogo } from "../../Assets/Icons/twitter.svg";
import { ReactComponent as Medium } from "../../Assets/Icons/medium.svg";
import { ReactComponent as Discord } from "../../Assets/Icons/discord.svg";
import { ReactComponent as Telegramsogo } from "./Assets/cryptodata-telegram.svg";
import { ReactComponent as LanguageIco } from "./Assets/language-icon.svg";
import { ReactComponent as MotoGPLogo } from "./Assets/motogp-logo.svg";

// IMPORTED IN APP
const Footer = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState("");

  // INPUT FORM CONTROL
  const [emailValue, setEmailValue] = useState(() => "");
  const [emailErrorMessage, setEmailErrorMessage] = useState(() => "");

  const handleChange = (event) => {
    const { value } = event.target;
    setEmailValue(() => value);
    if (emailErrorMessage) {
      setEmailErrorMessage(() => "");
    }
  };

  const validateEmail = () => {
    let emailError;

    if (!emailValue) {
      emailError = "This field can't be empty";
      setEmailErrorMessage(emailError);
    } else if (!/\S+@\S+\.\S+/.test(emailValue)) {
      emailError = "Invalid email!";
      setEmailErrorMessage(emailError);
    } else {
      console.log(emailValue);
      setEmailSent(true);
      setEmailSentMessage("Subscription is currently unavailable");
      setEmailValue(() => "");
    }
  };

  const sendEmailWithEnterKey = (event) => {
    if (event.key === "Enter") {
      validateEmail();
    }
  };

  return (
    <footer className="w-100">
      <div className="container-bg main-container px-3 d-flex align-items-center flex-column justify-content-center w-100 position-relative">
        <img
          src={CryptoDataLogo}
          alt="cryptodata-logo"
          className="position-absolute cryptodata-logo"
        />
        {/* <div className="z-index-1 text-center white-color moto-gp-regular font-size-32">
          Get your news delivered
        </div>
        <div className="z-index-1 text-center mb-5 white-color moto-gp-regular font-size-18">
          Only important news and sales. Never spam.
        </div>

        <div className="input-field d-flex align-items-center justify-content-center z-index-1">
          <div className="email-input-container">
            <InputGroup className="input-group mb-1">
              <Form.Control
                className="big-input"
                placeholder="Enter your email"
                aria-label="Adresa de email"
                aria-describedby="basic-addon2"
                onChange={handleChange}
                readOnly={emailSent}
                onKeyDown={sendEmailWithEnterKey}
              />
              <Form.Control
                className="small-input"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon2"
                readOnly={emailSent}
                onChange={handleChange}
                onKeyDown={sendEmailWithEnterKey}
              />
              <InputGroup.Text
                id="basic-addon-2"

                className={`btn-primary noselect ${emailSent && "email-sent"}`}
                onClick={(event) => { !emailSent && validateEmail(event) }}
              >
                Subscribe
              </InputGroup.Text>
            </InputGroup>
            <p className="ms-3 mt-0 pt-0 error-message moto-gp-bold">
              {emailErrorMessage}
            </p>
            <p className="ms-3 mt-0 pt-0 white-color text-center moto-gp-bold">
              {emailSentMessage}
            </p>
          </div>
        </div>
        <p className="z-index-1 text-center gray-color moto-gp-bold paragtaph-container font-size-16">
          We’ll never share your details with third parties.
          View our <span className="privacy-link" onClick={() => window.open("https://cryptodata.com/privacy-policy")}>Privacy Policy</span> for more info.
        </p> */}
      </div>
      <div className="main-container">
        <div className="nav-container moto-gp-regular d-flex justify-content-start gray-color align-items-center mb-3">
          <Logo className="logo me-2" />
        </div>
        <Row xs={1} sm={1} md={1}>
          <Col md={4} className="d-flex flex-column footer-info-col">
            <p className="moto-gp-regular white-color mb-2 font-size-14">
              CryptoDATA
            </p>
            <p className="moto-gp-regular  gray-color font-size-14">
              Blvd Pipera, no 1/II <br />
              Voluntari, Ilfov, Romania
            </p>

            <MotoGPLogo className="mb-3" />

            <div className="social-media-container d-flex align-items-center justify-content-start">
              <a
                className="social-media-link"
                href="https://www.facebook.com/cryptodataofficial"
                target="_blank"
                rel="noreferrer"
              >
                <FaceBookLogo />
              </a>
              <a
                className="social-media-link"
                href=" https://www.instagram.com/cryptodataofficial/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
              <a
                className="social-media-link"
                href="https://twitter.com/CryptoDATA_Tech"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterLogo />
              </a>
              <a
                className="social-media-link"
                href="https://medium.com/@cryptodata"
                target="_blank"
                rel="noreferrer"
              >
                <Medium />
              </a>

              <a
                className="social-media-link"
                href="https://discord.com/invite/CZC5M58Cmr"
                target="_blank"
                rel="noreferrer"
              >
                <Discord />
              </a>
            </div>
          </Col>

          {/* LINKS CONTAINER 1 START */}
          {/* For small screens */}
          <Col className="collapsable-footer-containers">
            <Accordion className="w-100" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  as={Card.Header}
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className="moto-gp-regular white-color mb-2 font-size-14">
                    Products
                  </p>
                  <AiOutlinePlus className="icon" />
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/impulse"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    IMPulse
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/basm"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    BASM
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/hidr"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    HidR
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/minter"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    MinteR
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header
                  as={Card.Header}
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className="moto-gp-regular white-color mb-2 font-size-14">
                    Software
                  </p>
                  <AiOutlinePlus className="icon" />
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/wispr"
                    className="moto-gp-regular gray-color mb-2 font-size-14 mb-3"
                  >
                    WispR
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/cryptodata-os"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    CryptoData OS
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header
                  as={Card.Header}
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className="moto-gp-regular white-color mb-2 font-size-14">
                    Projects
                  </p>
                  <AiOutlinePlus className="icon" />
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/projects"
                    className="moto-gp-regular gray-color mb-2 font-size-14 mb-3"
                  >
                    VOBP
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://bankofenergy.ro/"
                    className="moto-gp-regular gray-color mb-2 font-size-14 mb-3"
                  >
                    Bank of Energy
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/projects"
                    className="moto-gp-regular gray-color mb-2 font-size-14 mb-3"
                  >
                    Momentum
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://tinker-edu.ro/"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    Tinker
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Header
                  as={Card.Header}
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className="moto-gp-regular white-color mb-2 font-size-14">
                    Shop
                  </p>
                  <AiOutlinePlus className="icon" />
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/contact"
                    className="moto-gp-regular gray-color mb-2 font-size-14 mb-3"
                  >
                    Contact us
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/shop"
                    className="moto-gp-regular gray-color mb-2 font-size-14 mb-3"
                  >
                    Shop online
                  </a>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          {/* For larger screens */}
          <Col md={8} className="lg-footer-containers links-container-1">
            <Row xs={1} sm={1} md={2} lg={4}>
              <Col className="z-index-1">
                <div className="nav-links-footer d-flex align-items-start justify-content-start flex-column mb-3">
                  <h2 className="moto-gp-regular white-color mb-2 font-size-14 mb-3">
                    Products
                  </h2>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/impulse"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    IMPulse
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/basm"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    BASM
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/hidr"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    HidR
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/minter"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    MintR
                  </a>
                </div>
              </Col>
              <Col className="z-index-1">
                <div className="nav-links-footer d-flex align-items-start justify-content-start flex-column mb-3">
                  <h2 className="moto-gp-regular white-color mb-2 font-size-14 mb-3">
                    Software
                  </h2>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/wispr"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    WispR
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/cryptodata-os"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    CryptoData OS
                  </a>
                </div>
              </Col>
              <Col className="z-index-1">
                <div className="nav-links-footer d-flex align-items-start justify-content-start flex-column mb-3">
                  <h2 className="moto-gp-regular white-color mb-2 font-size-14 mb-3">
                    Projects
                  </h2>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/projects"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    VOBP
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://bankofenergy.ro/"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    Bank of Energy
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/projects"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    Momentum
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://tinker-edu.ro/"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    Tinker
                  </a>
                </div>
              </Col>
              <Col className="z-index-1">
                <div className="nav-links-footer d-flex align-items-start justify-content-start flex-column mb-3">
                  <h2 className="moto-gp-regular white-color mb-2 font-size-14 mb-3">
                    Shop
                  </h2>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/contact"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    Contact us
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://cryptodata.com/shop"
                    className="moto-gp-regular gray-color mb-2 font-size-14"
                  >
                    Shop online
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="bottom-footer mt-3 d-flex align-items-center justify-content-between flex-column-reverse flex-md-row">
          <div className="mt-3">
            <span className="moto-gp-regular  gray-color font-size-14 pt-3">
              Copyright © 2022 CryptoDATA
            </span>
          </div>

          <div className="privacy-part moto-gp-regular gray-color font-size-14 d-flex align-items-center justify-content-center pt-3 text-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cryptodata.com/privacy-policy"
              className="moto-gp-regular mb-0 gray-color font-size-14"
            >
              Privacy Policy
            </a>
            <span className="px-2">|</span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cryptodata.com/terms-and-conditions"
              className="moto-gp-regular mb-0 gray-color font-size-14"
            >
              Terms of Use
            </a>
            <span className="px-2">|</span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cryptodata.com/user-agreement"
              className="moto-gp-regular mb-0 gray-color font-size-14"
            >
              User Agreement
            </a>
          </div>

          <div className="language-ico moto-gp-regular  gray-color font-size-14 pt-3">
            <LanguageIco /> <span className="ms-1">English</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import "./Form.scss";

import axios from "axios";
import cogoToast from "cogo-toast";
import TermsConditions from "../../../../Assets/documents/MotoGPRaffleT&C.pdf";

class Form extends React.Component {
  state = {
    name: "",
    email: "",
    ticket: "",
  };

  onHandleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  sendEntry = async () => {
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.ticket === ""
    ) {
      //TODO: Add cogo toast
      cogoToast.error("One or more required fields are empty");
      return;
    }

    //TODO Validate Email
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.email
      ) === false
    ) {
      cogoToast.error("The email format is incorrect");
      return;
    }

    //TODO Validate Ticket
    if (
      /^[A-Za-z0-9]*$/.test(this.state.ticket) === false ||
      this.state.ticket.length < 7
    ) {
      cogoToast.error("The ticket format is incorrect");
      return;
    }

    try {
      let response = await axios.post(
        "/",
        {
          name: this.state.name,
          email: this.state.email,
          ticket: this.state.ticket,
        }
      );

      if (response.status === 200) {
        this.setState({
          email: "",
          name: "",
          ticket: "",
        });

        cogoToast.success(
          "The submission has been successfully sent. Please confirm your email."
        );
        return;
      }
    } catch (e) {
      if (e.response) {
        switch (e.response.status) {
          case 401:
            cogoToast.error("The email provided is not formatted correctly");
            return;
          case 402:
            cogoToast.error("The ticket is not valid");
            return;
          case 403:
            cogoToast.error("This ticket number is already taken");
            return;
          case 501:
            cogoToast.error("Server Error. Please try again later");
            return;
          case 501:
            cogoToast.error("Error creating Withdrawal Request");
            return;
          case 502:
            cogoToast.error("Error sending XDEN");
            return;
          case 503:
            cogoToast.error("Error updating Withdrawal Request");
            return;
          default:
            cogoToast.error(
              "An unknown error has occured. Please try again later"
            );
            return;
        }
      }
    } finally {
    }

    // cogoToast.success('The submission has been successfully sent. Please confirm your email.');
    return;
  };

  render() {
    return (
      <div className="motogp-raffle-form pt-5" id="register-form-id">
        <div className="main-container d-flex justify-content-center align-items-center">
          <div className="motogp-raffle-form-container position-relative">
            <div className="motogp-form d-flex flex-column justify-content-around align-items-center w-100 h-100">
              <div className="d-flex justify-content-between align-items-center w-100 motogp-form-field">
                <label className="white-color font-size-28 moto-gp-regular motogp-form-label">
                  Name
                </label>
                <input
                  onChange={this.onHandleChange}
                  name="name"
                  value={this.state.name}
                  className="motogp-input mb-2"
                />
              </div>
              <div className="d-flex justify-content-between align-items-center w-100 motogp-form-field">
                <label className="white-color font-size-28 moto-gp-regular motogp-form-label">
                  Email
                </label>
                <input
                  name="email"
                  onChange={this.onHandleChange}
                  value={this.state.email}
                  className="motogp-input mb-2"
                />
              </div>
              <div className="d-flex justify-content-between align-items-center w-100 motogp-form-field">
                <label className="white-color font-size-28 moto-gp-regular motogp-form-label">
                  Ticket number
                </label>
                <input
                  name="ticket"
                  onChange={this.onHandleChange}
                  value={this.state.ticket}
                  className="motogp-input mb-2"
                />
              </div>
              <div className="w-100 gray-color">
                <p className="moto-gp-regular font-size-15 white-color mb-0 mt-3">
                  * Registration open until 11th of November 2022
                </p>
                <p className="moto-gp-regular font-size-15 white-color mb-0">
                  * Please keep your ticket safe and don't throw it away. If you
                  win the MotoGP™ Raffle, you would also need the ticket to
                  validate your prize.
                </p>
                <p className="moto-gp-regular font-size-15 white-color mb-0">
                  * Only tickets for the 2022 Austrian MotoGP™ are accepted.
                </p>
                <a
                  href={TermsConditions}
                  target="_blank"
                  className="moto-gp-regular font-size-15 white-color mb-3 link-color"
                >
                  * Terms and Conditions apply.
                </a>
              </div>
              <div className="d-flex justify-content-between align-items-end w-100 mt-3 motogp-form-footer align-self-end">
                <button
                  onClick={this.sendEntry}
                  className="motogp-hero-btn position-relative m-auto"
                >
                  <span className="motogp-hero-btn-txt white-color moto-gp-regular font-size-20">
                    Register now
                  </span>
                  <span className="motogp-hero-btn-shape"></span>
                </button>
              </div>
            </div>
            <div className="motogp-raffle-form-border"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;

import React, { useEffect, useState } from 'react';

import CountDownTimer from './CountDownTimer';

import './Countdown.scss';

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

const Countdown = ({ slideIndex }) => {
  const [endTime, setEndTime] = useState(1658782800);
  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  // let endTime = 1658782800000 / 1000; // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <section id="homepage-countdown" className="homepage-countdown w-100">
      <div className="d-flex flex-wrap justify-content-between container-counter-new">
        {/* <MyCountdown
          date={new Date(2022, 25, 7, 18, 0, 0)}
          renderer={renderer}
        /> */}
        {slideIndex === 0 && (
          <>
            {/* <CountDownTimer endTime={1669305600}></CountDownTimer> */}
            {/* <CountDownTimer endTime={1668182400}></CountDownTimer> */}
            <CountDownTimer endTime={1669305600}></CountDownTimer>
          </>
        )}
        {slideIndex === 1 && (
          <>
            {" "}
            <CountDownTimer endTime={1661439600}></CountDownTimer>
          </>
        )}
        {slideIndex === 2 && (
          <>
            {" "}
            <CountDownTimer endTime={1661439600}></CountDownTimer>
          </>
        )}
        {slideIndex === 3 && (
          <>
            {" "}
            <CountDownTimer endTime={1663858800}></CountDownTimer>
          </>
        )}
        {slideIndex === 4 && (
          <>
            {" "}
            <CountDownTimer endTime={1662044400}></CountDownTimer>
          </>
        )}
        {slideIndex === 5 && (
          <>
            {" "}
            <CountDownTimer endTime={1665673200}></CountDownTimer>
          </>
        )}
        {slideIndex === 6 && (
          <>
            {" "}
            <CountDownTimer endTime={1667491200}></CountDownTimer>
          </>
        )}
      </div>
    </section>
  );
};

export default Countdown;

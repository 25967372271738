import React, { useRef, useState } from 'react';
import Slider from 'react-slick';

import LeftArrow from './Assets/arrow-left.png';
import RightArrow from './Assets/arrow-right.png';

import './Prizes.scss';

const ServiceSlider = ({ data, slideIndex, setSlideIndex }) => {
  const sliderRef = useRef();

  const sliderEnd = data.length - 1;

  const updateIndex = (newIndex, nextBtn) => {
    if (newIndex < 0) {
      newIndex = sliderEnd;
    } else if (newIndex > sliderEnd) {
      newIndex = 0;
    }
    if (sliderRef.current) {
      // sliderRef.current.slickGoTo(newIndex);
      if (nextBtn === true) sliderRef.current.slickNext();
      else sliderRef.current.slickPrev();
    }

    setSlideIndex(newIndex);
  };

  const settings = {
    className: "center",
    arrows: false,
    autoplaySpeed: 1500,
    draggable: true,
    variableWidth: true,
    speed: 500,
    centerMode: true,
    infinite: true,
    beforeChange: (current, next) => updateIndex(next),
    // afterChange: next => { updateIndex(next); console.log(next) },
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef}>
        {data.map((boxItem) => (
          <div key={boxItem.id} className="px-3">
            <div key={boxItem.id} className="text-white slider-box">
              {(boxItem.id === 2 || boxItem.id === 3 || boxItem.id === 5 || boxItem.id === 4 || boxItem.id === 6 || boxItem.id === 7) && <>
                <div className="overlay-gray"></div>
                <div className="overlay-text font-size-36 moto-gp-display text-center">
                  {boxItem.id === 2 ? "Winners announced" : "Winner announced"}
                </div>
              </>}
              {boxItem.image}
              <h4 className="text-center active-box-title mb-0">
                {boxItem.title}
              </h4>
            </div>
          </div>
        ))}
      </Slider>

      <div className="w-100 d-flex align-items-center justify-content-center mb-auto">
        <div
          onClick={() => {
            updateIndex(slideIndex - 1, false);
          }}
        >
          <img src={LeftArrow} alt="arrow" className="pointer arrow-btn" />
        </div>
        <div
          onClick={() => {
            updateIndex(slideIndex + 1, true);
          }}
        >
          <img src={RightArrow} alt="arrow" className="pointer arrow-btn" />
        </div>
      </div>
    </>
  );
};

export default ServiceSlider;

import React from "react";
import Hero from "./Hero/Hero";
import Prizes from "./Prizes/Prizes";
import FAQ from "./FAQ/FAQ";
import Ticket from "./Ticket/Ticket";
import Banner from "../../Components/Banner/Banner";
import Winners from "./Winners/Winners";

const Homepage = () => {
  return (
    <div className="motogp-raffle-home">
      <Banner />
      <Hero />
      <Ticket />
      <Prizes />
      <Winners />
      <FAQ />
    </div>
  );
};

export default Homepage;

import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 100,
  strokeWidth: 6,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper d-flex align-items-center justify-content-center flex-column">
      <div className="font-size-32 moto-gp-display">{time}</div>
      <div className="font-size-12 moto-gp-display">{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

export default function CountDownTimer({ endTime }) {
  // const [endTime, setEndTime] = useState(1658782800);
  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <div className="w-100">
      <div className="d-flex align-items-center justify-content-center justify-content-lg-start flex-column flex-sm-row">
        <div className="col1 d-flex align-items-center justify-content-center mb-2 mb-sm-0">
          <div className="me-2">
            <CountdownCircleTimer
              {...timerProps}
              strokeWidth={8}
              colors="#c80502"
              duration={daysDuration}
              initialRemainingTime={remainingTime}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </div>
          <div className="me-0 me-sm-2">
            <CountdownCircleTimer
              {...timerProps}
              strokeWidth={8}
              colors="#c80502"
              duration={daySeconds}
              initialRemainingTime={remainingTime % daySeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > hourSeconds,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </div>
        </div>
        <div className="col2 d-flex align-items-center justify-content-center">
          <div className="me-2">
            <CountdownCircleTimer
              {...timerProps}
              colors="#c80502"
              strokeWidth={8}
              duration={hourSeconds}
              initialRemainingTime={remainingTime % hourSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </div>
          <div className="">
            <CountdownCircleTimer
              {...timerProps}
              colors="#c80502"
              isSmoothColorTransition={true}
              strokeWidth={8}
              duration={minuteSeconds}
              initialRemainingTime={remainingTime % minuteSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > 0,
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime("seconds", getTimeSeconds(elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';

import MinteRImg from '../../../Assets/images/background.png';
import Countdowns from '../../../Components/Timer/Countdown';
import BasmImg from './Assets/basm-render.png';
import Dashes from './Assets/dashes.png';
import BikeImg from './Assets/ducati-bike.png';
import AccessImg from './Assets/ga-ticket.png';
import HidrImg from './Assets/hidr.png';
import ImpulseImg from './Assets/impulse.png';
import VipImg from './Assets/vip-ticket.png';
import ServiceSlider from './ServiceSlider';

import './Prizes.scss';

const Prizes = () => {
  const [slideIndex, setSlideIndex] = useState(() => 0);

  const sliderData = [
    {
      id: 1,
      image: (
        <img
          src={BikeImg}
          loading="lazy"
          alt="blockchain representation"
          className="slide-image bike-img"
        />
      ),
      title: "Ducati Bike",
    },
    {
      id: 2,
      image: (
        <img
          src={AccessImg}
          loading="lazy"
          alt="XID coin"
          className="slide-image ticket-img"
        />
      ),
      title: "Access Ticket",
    },
    {
      id: 3,
      image: (
        <img
          src={VipImg}
          loading="lazy"
          alt="Hotspot"
          className="slide-image ticket-img"
        />
      ),
      title: "VIP Ticket",
    },
    {
      id: 4,
      image: (
        <img
          src={HidrImg}
          loading="lazy"
          alt="Hotspot"
          className="slide-image hidr-img"
        />
      ),
      title: "HidR",
    },
    {
      id: 5,
      image: (
        <img
          src={ImpulseImg}
          loading="lazy"
          alt="Hotspot"
          className="slide-image impulse-img"
        />
      ),
      title: "IMPulse",
    },
    {
      id: 6,
      image: (
        <img
          src={MinteRImg}
          loading="lazy"
          alt="Hotspot"
          className="slide-image bike-img minter-img"
        />
      ),
      title: "Minter G",
    },
    {
      id: 7,
      image: (
        <img
          src={BasmImg}
          loading="lazy"
          alt="Hotspot"
          className="slide-image basm-img"
        />
      ),
      title: "BASM",
    },
  ];

  return (
    <section
      id="prizes-section"
      className="what-section"
      style={{ overflow: "hidden" }}
    >
      <div className="main-container">
        <div className="row">
          <div className="col-lg-6 col-12 order-lg-1 order-1 d-flex flex-column justify-content-center align-items-start">
            <h2 className="mb-3 font-size-120 moto-gp-display mx-auto mx-lg-0">
              Prizes
            </h2>
            <img
              src={Dashes}
              alt="dashes"
              className="img-fluid mb-4 mx-auto mx-lg-0"
            />

            <Countdowns slideIndex={slideIndex} />
            <div className={`description description-991 mx-auto mx-lg-0 my-4`}>
              <p className="moto-gp-regular font-size-24 gray-color line-height-11 text-center text-lg-start mx-auto m-lg-0">
                {slideIndex === 1 && "Check your email to see if you are one of the lucky winners."}
                {(slideIndex === 2 || slideIndex === 4 || slideIndex === 3 || slideIndex === 5 || slideIndex === 6) && "Check your email to see if you are the lucky winner."}
                {slideIndex !== 2 && slideIndex !== 1 && slideIndex !== 4 && slideIndex !== 3 && slideIndex !== 5 && slideIndex !== 6 &&
                  "Enter your ticket’s serial number to be in with the chance of taking home some amazing prizes."}
              </p>
            </div>

            {/* <button className="check-prizes-btn position-relative mx-auto mx-lg-3">
              <span className="check-prizes-btn-txt white-color moto-gp-regular font-size-20 text-nowrap">Check regulations</span>
              <span className="check-prizes-btn-shape"></span>
            </button> */}
          </div>

          {/* <ServicesSlider data={sliderData} /> */}
          <div className="col-lg-6 col-12 order-lg-2 order-1 position-relative slider-col">
            <div className="pos-absolute-slider">
              <ServiceSlider
                setSlideIndex={setSlideIndex}
                slideIndex={slideIndex}
                data={sliderData}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prizes;

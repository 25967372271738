import React from "react";
// style
import "./Navbar.scss";
// components
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { ReactComponent as Logo } from "./assets/logo-white.svg";
import LogoSMVisible from "./assets/logo-white.png";
import { ReactComponent as DotsMenuIcon } from "./assets/dots.svg";
import { ReactComponent as ShopIcon } from "./assets/shop-icon.svg";
// import { ReactComponent as UserIcon } from "./assets/user-icon.svg";

const Navigation = () => {
  const scrollToTopPage = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section id="navigation-section">
      <Navbar id="navigation" collapseOnSelect expand="md" bg="white" fixed="top" className="font-nunito-bold ">
        <div className="main-container container-navbar nav d-flex justify-content-between align-items-center">
          <Navbar.Brand>
            <a href="/" className="logo-lg" onClick={() => scrollToTopPage()}>
              <Logo className="nav-logo" />
            </a>

            <a href="https://cryptodata.com/cart" target="_blank" className="logo-sm">
              <ShopIcon className="shop-icon-sm" />
            </a>
          </Navbar.Brand>

          <a onClick={() => scrollToTopPage()}>
            <img src={LogoSMVisible} alt="logo" className="logo-sm-visible" />
          </a>

          <Navbar.Toggle>
            <DotsMenuIcon />
          </Navbar.Toggle>

          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
            <Nav>
              <Nav.Link
                eventKey="1"
                className="links font-gotham-book products-link"
                href="https://cryptodata.com/products"
                target="_blank"
              >
                Products
              </Nav.Link>

              <Nav.Link
                eventKey="2"
                className="links font-gotham-book"
                href="https://cryptodata.com/cyber-security"
                target="_blank"
              >
                Security
              </Nav.Link>

              {/* <NavDropdown title="Company" id="collasible-nav-dropdown" className="links font-gotham-book">
                <div className="dropdown-container">
                  <NavDropdown.Item href="https://cryptodata.com/projects" className="projects-link">
                    <p className="mb-0 font-gotham-book pl-0 pl-sm-3">Projects</p>
                  </NavDropdown.Item>

                  <NavDropdown.Item href="https://cryptodata.com/about-us">
                    <p className="mb-0 font-gotham-book pl-0 pl-sm-3">About us</p>
                  </NavDropdown.Item>
                </div>
              </NavDropdown> */}

              <Nav.Link eventKey="3" className="links font-gotham-book" href="https://cryptodata.com/projects" target="_blank">
                Projects
              </Nav.Link>
              <Nav.Link eventKey="4" className="links font-gotham-book" href="https://cryptodata.com/shop" target="_blank">
                Shop
              </Nav.Link>
              <Nav.Link eventKey="5" className="links font-gotham-book" href="https://cryptodata.com/login" target="_blank">
                Login
              </Nav.Link>
            </Nav>

            <div className="tools-container">
              <ShopIcon className="shop-icon" onClick={() => window.open("https://cryptodata.com/cart", "blank")} />
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </section>
  );
};

export default Navigation;

import React from "react";
import "./FAQ.scss";
import { Accordion } from "react-bootstrap";
import { ReactComponent as Plus } from "./Assets/test-plus.svg";
import DashImg from "./Assets/dash.png";
import TermsConditions from "../../../Assets/documents/MotoGPRaffleT&C.pdf";

//TODO adaugat terms and conditions and link
const FAQ = () => {
  const FAQData = [
    {
      question: "When will the prizes be handed out?",
      answer: `Each listed prize has a designated countdown.`,
      terms: (
        <a className="link-color" target="_blank" href={TermsConditions}>
          {" "}
          Read the full Terms & Conditions here.
        </a>
      ),
    },
    {
      question: "How can I register for the raffle?",
      answer: `Complete the form until 6th of November 2022 23:59 UTC for a chance to win the
Ducati Bike or until 25.08 20:00 UTC for a chance to win MotoGP™ Tickets. `,
      terms: (
        <a className="link-color" target="_blank" href={TermsConditions}>
          {" "}
          Read the full Terms & Conditions here.
        </a>
      ),
    },
    {
      question: " Can I choose which GP to go to if I win tickets as my prize?",
      answer: `No. Both the General Access and the VIP tickets grants you access to the San
Marino and Riviera Di Rimini, Italy race from 02-04 September`,
    },
    // {
    //   question: "What if I don’t receive the confirmation email?",
    //   answer: `The Motorrad Grand Prix von Österreich starts on August 19th at The Red Bull Ring in Spielberg, Austria. And we need your help to get fans fired up for this exciting event! That’s because at the end of the actual race in Austria, we’ll randomly select 10 lucky winners, who will each receive a special CryptoDATA prize featuring a Ducati Bike, VIP and general access tickets, CryptoDATA devices and so much more.
    //                 So register and fill the form at the top of this page with your ticket number for your chance to win!`,
    // },
    {
      question: "Can I register with any MotoGP™ ticket from 2022?      ",
      answer: `Unfortunately, no. We only validate registrations with tickets for the 2022 Austrian MotoGP™.
      `,
    },
    {
      question: "Can I register more than one ticket under the same email?",
      answer: `Yes.`,
    },
  ];

  return (
    <section id="faq-moto-gp" className="mt-5">
      <div className="main-container d-flex flex-align-center justify-content-center flex-column">
        <div className="title mb-4">
          <h2 className="font-size-120 moto-gp-display text-center text-lg-start">
            FAQ
          </h2>
        </div>

        <img
          src={DashImg}
          alt="dash"
          className="img-fluid dashes-img mb-3 mx-auto mx-lg-0"
        />
        <Accordion className="dark-grey-color " defaultActiveKey="0">
          {FAQData.map((row, index) => {
            return (
              <Accordion.Item eventKey={`${index}`} key={index}>
                <Accordion.Header className="moto-gp-regular mb-10px ">
                  <div className="font-size-18 moto-gp-regular mb-10px pe-2 ">
                    {row.question}
                  </div>{" "}
                  <div className="accordion-custom-button">
                    <Plus className="button-transition" />
                  </div>
                </Accordion.Header>

                <Accordion.Body className="pe-5">
                  <p className="moto-gp-regular font-size-18 line-height-18 gray-color">
                    {row.answer}
                  </p>
                  {row.terms}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </section>
  );
};

export default FAQ;

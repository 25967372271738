import React from "react";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.scss"

const ErrorPage = () => {

  const navigate = useNavigate();

  return <section id="error-page" className="pt-5">
    <div className="error-page-container d-flex align-items-center justify-content-center flex-column mt-5" >

      <span className="font-size-120 moto-gp-display text-center line-height-11">
        404 <br /> Page not found!
      </span>

      <button className="motogp-hero-btn position-relative mt-4" onClick={() => navigate("/")}>
        <span className="motogp-hero-btn-txt white-color moto-gp-regular font-size-20">
          Go back
        </span>
        <span className="motogp-hero-btn-shape"></span>
      </button>
    </div>
  </section>;
};

export default ErrorPage;

import React from "react";
import Form from "./Form/Form";
import { Link as ScrollLink } from "react-scroll";
import "./Hero.scss";

const Hero = () => {
  return (
    <div className="motogp-raffle-hero" id="hero-anchor-id">
      <div className="main-container d-flex flex-column justify-content-center align-items-center w-100">
        <div className="motogp-raffle-hero-txt-mobile"></div>
        <div className="motogp-raffle-hero-txt mx-auto">
          <h2 className="moto-gp-display font-size-120 white-color line-height-1">
            CryptoDATA
          </h2>
          <h2 className="moto-gp-display font-size-120 white-color line-height-1">
            MotoGP™ Raffle
          </h2>
          {/* <p className="moto-gp-regular font-size-24 white-color line-height-12 my-5">
            Insert your ticket’s serial number to be in with a chance of winning
            a Ducati bike! <br></br>
          </p> */}

          <ScrollLink to="prizes-section" offset={-90} spy={true}>
            <button className="motogp-hero-btn position-relative mt-5">
              <span className="motogp-hero-btn-txt white-color moto-gp-regular font-size-20">
                CHECK NOW
              </span>
              <span className="motogp-hero-btn-shape"></span>
            </button>
          </ScrollLink>
        </div>
        {/* <Form /> */}
      </div>
    </div>
  );
};

export default Hero;
